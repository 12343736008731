export default {
  "navbar": {
    "education": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Education"])},
    "experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experience"])},
    "skills": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skills"])},
    "projects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projects"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
    "images": {
      "sun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change theme to light"])},
      "moon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change theme to dark"])},
      "polishFlag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change language to Polish"])},
      "greatBritainFlag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change language to English"])}
    }
  },
  "landingPage": {
    "myNameIs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hi, my name is"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dhanush Rahul Sai Charipally."])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A highly motivated software developer with Master's in Applied Computer Science and over 3 years of experience in front-end and back-end technologies. Proven expertise in full-stack development, utilizing a wide range of programming languages and frameworks, including Java, Python, JavaScript, React, Angular, and Vue. Excited to bring my technical proficiency, problem-solving abilities, and dedication to excellence to a dynamic software development team."])}
  },
  "education": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Education"])},
    "educationItems": {
      "bachelors": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jawaharlal Nehru Technological University"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thanks to the education from this institution, I have gained a basic understanding of computer science, which not only broadened my horizons, but also gave me plenty of knowledge about databases and the development of desktop or web applications."])},
        "major": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bachelor's of Technology in Computer Science"])}
      },
      "masters": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saint Francis Xavier University"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From software development to Machine Learning and Natural language processing."])},
        "major": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Master's Degree in Applied Computer Science"])}
      }
    }
  },
  "experience": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experience"])},
    "experienceItems": {
      "associate": {
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projects Undertaken:"])},
        "profession": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Associate Software Developer"])},
        "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feb 2021 - Aug 2022 "])},
        "length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(3 years 2 months)"])},
        "companyProjects": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projects undertaken"])},
          "companyProjectsItems": {
            "BMA": {
              "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A web application tailored specifically for managing the needs of students with disabilities. The platform facilitates an efficient, user-friendly environment where students can securely log in, upload necessary disability documentation, and subsequently schedule assessments with qualified assessors. This streamlined process ensures that students are appropriately accommodated in their educational environments, thereby enhancing accessibility and compliance with educational standards and regulations."])},
              "techStack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[React.js, Redux, Ruby on Rails, GraphQL, Docker, CI/CD pipelines, Postman, GitHub, Jira, Agile methodologies]"])},
              "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Implemented numerous React components, creating a modular, scalable front end. Utilized React Hooks and Context for managing local and global state within the application, thereby ensuring smooth data flows and reactivity. Developed robust RESTful APIs using Ruby on Rails. Conducted comprehensive API testing using Postman to verify the functionality, reliability, and security of the application services. Actively participated in all phases of the Agile development process, including sprint planning, daily scrum calls, and retrospectives, using Jira to track and manage project issues and progress effectively."])}
            },
            "DSA": {
              "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A web application meticulously designed to facilitate access to resources for students with disabilities. The platform enables students and parents to sign up and subscribe to essential resources, streamlining the process of resource allocation tailored to individual needs. This application enhances the accessibility of services, ensuring compliance with educational regulations and standards."])},
              "techStack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[Angular.js, Node.js, JavaScript, PostgreSQL, Bitbucket, Jira, Agile methodologies.]"])},
              "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Engineered interactive user interfaces using Angular.js, ensuring a seamless and responsive user experience. Developed and integrated robust RESTful APIs using JavaScript and Node.js, which were crucial for backend data management and front-end interactions. Implemented comprehensive testing strategies using JUnit to ensure the reliability and efficiency of the application. Participated actively in the Agile development lifecycle, utilizing Jira for task management and sprint tracking. "])}
            },
            "yantraka": {
              "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A cutting-edge web application leveraging advanced machine learning algorithms to forecast trends in cryptocurrency markets. The application provides highly accurate, 30-minute interval predictions, utilizing historical data and real-time analysis to serve financial professionals and enthusiasts with actionable insights."])},
              "techStack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[Angular.js, Python, Machine Learning, PostgreSQL, BitBucket, Jira, Agile methodologies.]"])},
              "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spearheaded the development of the application using Angular.js, creating a robust and adaptable front-end architecture. Managed the full software development cycle following Agile practices, ensuring timely deliveries and adherence to project specifications. "])}
            },
            "telligent": {
              "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An integrated community platform that supports a range of applications, including forums, blogs, articles, and wikis, designed to enhance user engagement and content accessibility. This platform allows users to interact dynamically and access a wealth of information seamlessly.  "])},
              "techStack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[HTML5, CSS3, Bootstrap, JavaScript, .NET Framework, Jira, Agile methodologies.]"])},
              "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Constructed and styled web pages using HTML5 and CSS3, implementing advanced design techniques to enhance visual appeal and user experience. Added dynamic and interactive elements using JavaScript, significantly improving client-side functionality. Utilized the .NET Framework for backend processes, integrating various components smoothly to ensure a cohesive user experience. Employed Agile methodologies for project management, ensuring efficient workflow and timely updates throughout the development process."])}
            },
            "greenBean": {
              "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dengey"])},
              "techStack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[Spring Boot, Hibernate, Spring Security, MySQL, Node.js, Angular, React]"])},
              "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acquired proficiency in technologies such as Spring Boot, Node.js, Angular, and React. Gained insights into the workings of Microsoft Dynamics and its practical applications. Built an application for the company to track their tasks and view progress. Revolutionized projects by seamlessly integrating the cutting-edge technologies of HTML, CSS, and Bootstrap, elevating them to unprecedented levels of aesthetic appeal and user experience. Identify and resolve software defects and bugs to ensure high-quality deliverables."])}
            }
          }
        }
      }
    }
  },
  "skills": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skills"])},
    "useRegularly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I use regularly"])},
    "hadContact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I had contact with"])}
  },
  "projects": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projects"])},
    "projectsItems": {
      "WDI": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data analysis on World Development Indicators"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Led a thorough analysis of 30 countries over 30 years using World Bank data and 25 indicators. Applied Python and Pandas for preprocessing, handling missing values, and normalization. Employed data visualization libraries for similarity matrix visualization and conducted Principal Component Analysis (PCA) for dimensionality reduction. Introduced novel indices through feature engineering and utilized k-Means and hierarchical clustering for pattern identification. Visualized economic trends with Matplotlib, highlighting Python's role in data-driven policy decisions and predicting global economic trends."])}
      },
      "FDI": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Food Drug Interaction using Machine Learning Methodologies"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This research project focuses on leveraging machine learning to address food-drug interactions (FDIs), considering positive, negative, and adverse reactions. The team utilizes open-sourced datasets from DrugBank and FooDB, employing various similarity indexes and machine learning algorithms, including k-Means, Gaussian methods, and neural networks. The dataset undergoes preprocessing, including filtering compounds and calculating molecular similarity ratios. The models are evaluated based on accuracy and precision, with the Multi-Layer Perceptron showing promising results. The paper highlights the importance of understanding FDIs in healthcare due to their impact on morbidity and mortality, emphasizing the need for further research and gold standard datasets."])}
      },
      "toxicity": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toxicity Prediction of Chemicals - A Machine Learning Project"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The project focuses on predicting chemical toxicity using machine learning, utilizing a training dataset with SMILE molecules and assay IDs. Features were extracted using chemical information libraries like RDKit and PubChem. Dataset balancing was performed using Random Over Sampler, and the XGB Classifier emerged as the best model. Hyperparameter tuning with Randomized Search CV improved model performance. The final model achieved an 83.02 on Kaggle, with reproducibility ensured through a Dockerfile."])}
      },
      "itTicketing": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IT Ticketing Platform"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A specialized web application tailored for IT companies, designed to streamline the process of issue reporting and resolution. Spearheaded both front-end and back-end development for a web-based IT ticketing system. Utilized Angular.js to create an intuitive and user-friendly interface, and employed responsive design to ensure compatibility across various devices. Developed secure, efficient back-end APIs using JavaScript and Node.js with PostgreSQL for robust data management. Enhanced database performance and integrity through optimized schema design. Conducted extensive testing to guarantee high functionality and reliability under diverse scenarios. Actively engaged in the Agile process, collaborating with team members to refine the application continually based on feedback and technical insights."])}
      },
      "rental": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nish Rents - A House Rental App"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1qEngineered an innovative Android house rental application for Antigonish, simplifying accommodation searches with real-time updates and detailed descriptions. Leveraging a modern tech stack including Kotlin and Android Jetpack Compose, the app offers a user-friendly interface and robust security measures, ensuring a seamless experience for renters and property owners. Integrated advanced features like Material Design components and Lottie animations, setting a new standard for rental platforms in the region and providing a comprehensive solution for individuals and families relocating to Antigonish."])}
      },
      "portfolio": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portfolio"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A website summarizing information about myself. It includes information about my education, commercial experience and projects, whilst being interactive and animated."])}
      }
    }
  },
  "contact": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])}
  },
  "footer": {
    "allRightsReserved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["© All rights reserved"])}
  },
  "author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dhanush Charipally"])}
}